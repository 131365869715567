import React from 'react'
import ComingSoon from './Components/ComingSoon/ComingSoon'


export default function App() {
  return (
    <div>
      <ComingSoon />
    </div>
  )
}
